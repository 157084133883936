export const USER_DETAILS_FORM: UserDetailsFormConfigProps = {
  AGE_OPTIONS: [
    { value: null, text: 'Please select' },
    { value: '<18', text: 'Under 18' },
    { value: '18-25', text: '18-25' },
    { value: '26-35', text: '26-35' },
    { value: '36-45', text: '36-45' },
    { value: '46-55', text: '46-55' },
    { value: '56-65', text: '56-65' },
    { value: '65+', text: '65+' }
  ],

  GENDER_OPTIONS: [
    { value: null, text: 'Please select' },
    { value: 'male', text: 'Male' },
    { value: 'female', text: 'Female' },
    { value: 'other', text: 'Other' }
  ],

  ACCENT_OPTIONS: [
    { value: null, text: 'Please select' },
    { value: 'london', text: 'London' },
    { value: 'yorkshire', text: 'Yorkshire' },
    { value: 'geordie', text: 'Geordie' },
    { value: 'midlands', text: 'Midlands' },
    { value: 'northern', text: 'Northern' },
    { value: 'southern', text: 'Southern' },
    { value: 'irish', text: 'Irish' },
    { value: 'irishNorthern', text: 'Northern Irish' },
    { value: 'scottish', text: 'Scottish' },
    { value: 'welsh', text: 'Welsh' },
    { value: 'german', text: 'German' },
    { value: 'french', text: 'French' },
    { value: 'spanish', text: 'Spanish' },
    { value: 'italian', text: 'Italian' },
    { value: 'european', text: 'European' },
    { value: 'asianSouth', text: 'South Asian (Indian, Pakistani etc)' },
    { value: 'asianEast', text: 'East Asian (China, Japan etc)' },
    { value: 'asianOther', text: 'Asian (Other)' },
    { value: 'african', text: 'African' },
    { value: 'africanSouth', text: 'South African' },
    { value: 'australian', text: 'Australian' },
    { value: 'newZealand', text: 'New Zealand' },
    { value: 'americanNorth', text: 'North American' },
    { value: 'americanSouth', text: 'South American' },
    { value: 'other', text: 'Other' }
  ],

  REGION_OPTIONS: [
    { value: null, text: 'Please select' },
    { value: 'london', text: 'London' },
    { value: 'southEast', text: 'South East' },
    { value: 'southWest', text: 'South West' },
    { value: 'englandEast', text: 'East of England' },
    { value: 'midlandsEast', text: 'East Midlands' },
    { value: 'midlandsWest', text: 'West Midlands' },
    { value: 'yorkshireHumber', text: 'Yorkshire and the Humber' },
    { value: 'northEast', text: 'North East' },
    { value: 'northWest', text: 'North West' },
    { value: 'northernIreland', text: 'Northern Ireland' },
    { value: 'ireland', text: 'Ireland' },
    { value: 'scotland', text: 'Scotland' },
    { value: 'wales', text: 'Wales' },
    { value: 'other', text: 'Other' }
  ],

  // Source Nationalencyklopedin (2010) - https://en.wikipedia.org/wiki/List_of_languages_by_number_of_native_speakers
  LANGUAGE_OPTIONS: [
    { value: null, text: 'Please select' },
    { value: 'english', text: 'English' },
    { value: 'other', text: 'Other' },
    { value: 'akan', text: 'Akan' },
    { value: 'amharic', text: 'Amharic' },
    { value: 'arabic', text: 'Arabic' },
    { value: 'assamese', text: 'Assamese' },
    { value: 'awadhi', text: 'Awadhi' },
    { value: 'azerbaijani', text: 'Azerbaijani' },
    { value: 'balochi', text: 'Balochi' },
    { value: 'belarusian', text: 'Belarusian' },
    { value: 'bengali', text: 'Bengali' },
    { value: 'bhojpuri', text: 'Bhojpuri' },
    { value: 'bulgarian', text: 'Bulgarian' },
    { value: 'burmese', text: 'Burmese' },
    { value: 'cantonese', text: 'Cantonese' },
    { value: 'cebuano', text: 'Cebuano' },
    { value: 'chewa', text: 'Chewa' },
    { value: 'chhattisgarhi', text: 'Chhattisgarhi' },
    { value: 'chinese(other)', text: 'Chinese (Other)' },
    { value: 'chittagonian', text: 'Chittagonian' },
    { value: 'czech', text: 'Czech' },
    { value: 'deccan', text: 'Deccan' },
    { value: 'dhundhari', text: 'Dhundhari' },
    { value: 'dutch', text: 'Dutch' },
    { value: 'easternMin', text: 'Eastern Min' },
    { value: 'french', text: 'French' },
    { value: 'fula', text: 'Fula' },
    { value: 'gan', text: 'Gan' },
    { value: 'german', text: 'German' },
    { value: 'greek', text: 'Greek' },
    { value: 'gujarati', text: 'Gujarati' },
    { value: 'haitianCreole', text: 'Haitian Creole' },
    { value: 'hakka', text: 'Hakka' },
    { value: 'haryanvi', text: 'Haryanvi' },
    { value: 'hausa', text: 'Hausa' },
    { value: 'hiligaynon', text: 'Hiligaynon' },
    { value: 'hindi', text: 'Hindi' },
    { value: 'hmong', text: 'Hmong' },
    { value: 'hungarian', text: 'Hungarian' },
    { value: 'igbo', text: 'Igbo' },
    { value: 'ilocano', text: 'Ilocano' },
    { value: 'italian', text: 'Italian' },
    { value: 'japanese', text: 'Japanese' },
    { value: 'javanese', text: 'Javanese' },
    { value: 'jin', text: 'Jin' },
    { value: 'kannada', text: 'Kannada' },
    { value: 'kazakh', text: 'Kazakh' },
    { value: 'khmer', text: 'Khmer' },
    { value: 'kinyarwanda', text: 'Kinyarwanda' },
    { value: 'kirundi', text: 'Kirundi' },
    { value: 'konkani', text: 'Konkani' },
    { value: 'korean', text: 'Korean' },
    { value: 'kurdish', text: 'Kurdish' },
    { value: 'madurese', text: 'Madurese' },
    { value: 'magahi', text: 'Magahi' },
    { value: 'maithili', text: 'Maithili' },
    { value: 'malagasy', text: 'Malagasy' },
    { value: 'malay', text: 'Malay' },
    { value: 'malayalam', text: 'Malayalam' },
    { value: 'mandarin', text: 'Mandarin' },
    { value: 'marathi', text: 'Marathi' },
    { value: 'marwari', text: 'Marwari' },
    { value: 'mossi', text: 'Mossi' },
    { value: 'nepali', text: 'Nepali' },
    { value: 'odia', text: 'Odia' },
    { value: 'oromo', text: 'Oromo' },
    { value: 'pashto', text: 'Pashto' },
    { value: 'persian', text: 'Persian' },
    { value: 'polish', text: 'Polish' },
    { value: 'portuguese', text: 'Portuguese' },
    { value: 'punjabi', text: 'Punjabi' },
    { value: 'quechua', text: 'Quechua' },
    { value: 'romanian', text: 'Romanian' },
    { value: 'russian', text: 'Russian' },
    { value: 'saraiki', text: 'Saraiki' },
    { value: 'serbo-croatian', text: 'Serbo-Croatian' },
    { value: 'shona', text: 'Shona' },
    { value: 'sindhi', text: 'Sindhi' },
    { value: 'sinhala', text: 'Sinhala' },
    { value: 'somali', text: 'Somali' },
    { value: 'southernMin', text: 'Southern Min' },
    { value: 'spanish', text: 'Spanish' },
    { value: 'sundanese', text: 'Sundanese' },
    { value: 'swedish', text: 'Swedish' },
    { value: 'sylheti', text: 'Sylheti' },
    { value: 'tagalog', text: 'Tagalog' },
    { value: 'tamil', text: 'Tamil' },
    { value: 'telugu', text: 'Telugu' },
    { value: 'thai', text: 'Thai' },
    { value: 'turkish', text: 'Turkish' },
    { value: 'turkmen', text: 'Turkmen' },
    { value: 'ukrainian', text: 'Ukrainian' },
    { value: 'urdu', text: 'Urdu' },
    { value: 'uyghur', text: 'Uyghur' },
    { value: 'uzbek', text: 'Uzbek' },
    { value: 'vietnamese', text: 'Vietnamese' },
    { value: 'wu', text: 'Wu' },
    { value: 'xhosa', text: 'Xhosa' },
    { value: 'xiang', text: 'Xiang' },
    { value: 'yoruba', text: 'Yoruba' },
    { value: 'yue', text: 'Yue' },
    { value: 'zhuang', text: 'Zhuang' },
    { value: 'zulu', text: 'Zulu' }
  ],

  SECOND_LANGUAGE_OPTIONS: [
    { text: 'None', value: null },
    { value: 'deutsch', text: 'Deutsch' },
    { value: 'englisch', text: 'Englisch' },
    { value: 'albanisch', text: 'Albanisch (Toskisch)' },
    { value: 'arabisch', text: 'Arabisch' },
    { value: 'armenisch', text: 'Armenisch' },
    { value: 'aseri', text: 'Aseri (Aserbaidschanisch)' },
    { value: 'bosnisch', text: 'Bosnisch' },
    { value: 'bulgarisch', text: 'Bulgarisch' },
    { value: 'chinesisch', text: 'Chinesisch' },
    { value: 'dänisch', text: 'Dänisch' },
    { value: 'dari', text: 'Dari (Neupersisch)' },
    { value: 'estnisch', text: 'Estnisch' },
    { value: 'finnisch', text: 'Finnisch' },
    { value: 'flämisch', text: 'Flämisch' },
    { value: 'französisch', text: 'Französisch' },
    { value: 'georgisch', text: 'Georgisch' },
    { value: 'gilbertesisch', text: 'Gilbertesisch' },
    { value: 'griechisch', text: 'Griechisch' },
    { value: 'hebräisch', text: 'Hebräisch (Iwrith)' },
    { value: 'hindi', text: 'Hindi' },
    { value: 'irisch', text: 'Irisch' },
    { value: 'isländisch', text: 'Isländisch' },
    { value: 'italienisch', text: 'Italienisch' },
    { value: 'japanisch', text: 'Japanisch' },
    { value: 'kasachisch', text: 'Kasachisch' },
    { value: 'katalanisch', text: 'Katalanisch' },
    { value: 'khmer', text: 'Khmer' },
    { value: 'kirgiesisch', text: 'Kirgiesisch' },
    { value: 'koreanisch', text: 'Koreanisch' },
    { value: 'kroatisch', text: 'Kroatisch' },
    { value: 'kurdisch', text: 'Kurdisch' },
    { value: 'laotisch', text: 'Laotisch' },
    { value: 'latain', text: 'Latain' },
    { value: 'lettisch', text: 'Lettisch' },
    { value: 'litauisch', text: 'Litauisch' },
    { value: 'makedonisch', text: 'Makedonisch' },
    { value: 'maltesisch', text: 'Maltesisch' },
    { value: 'moldauisch', text: 'Moldauisch' },
    { value: 'mongolisch', text: 'Mongolisch' },
    { value: 'neugriechisch', text: 'Neugriechisch' },
    { value: 'niederländisch', text: 'Niederländisch' },
    { value: 'norwegisch', text: 'Norwegisch' },
    { value: 'persisch', text: 'Persisch' },
    { value: 'polnisch', text: 'Polnisch' },
    { value: 'portugiesisch', text: 'Portugiesisch' },
    { value: 'quechua', text: 'Quechua (Indianersprache)' },
    { value: 'rumänisch', text: 'Rumänisch' },
    { value: 'russisch', text: 'Russisch' },
    { value: 'schwedisch', text: 'Schwedisch' },
    { value: 'serbisch', text: 'Serbisch' },
    { value: 'singhalesisch', text: 'Singhalesisch' },
    { value: 'slowakisch', text: 'Slowakisch' },
    { value: 'slowenisch', text: 'Slowenisch' },
    { value: 'spanisch', text: 'Spanisch' },
    { value: 'tadschiekisch', text: 'Tadschiekisch' },
    { value: 'thailändisch', text: 'Thailändisch' },
    { value: 'tschechisch', text: 'Tschechisch' },
    { value: 'türkisch', text: 'Türkisch' },
    { value: 'turkmenisch', text: 'Turkmenisch' },
    { value: 'ukrainisch', text: 'Ukrainisch' },
    { value: 'ungarisch', text: 'Ungarisch' },
    { value: 'urdu', text: 'Urdu' },
    { value: 'usbekisch', text: 'Usbekisch' },
    { value: 'vietnamesisch', text: 'Vietnamesisch' },
    { value: 'andere', text: 'Andere' }
  ]
}
